import { Modal, VerticalStack } from '@shopify/polaris';
import React from 'react';

function ActionModal({ popUpActive, handlePreviewPopUp, popUpTitle, primaryAction, secondaryActions, popUpContent, popUpContent1 }) {
    return (
        <Modal
            open={popUpActive}
            onClose={() => handlePreviewPopUp()}
            title={popUpTitle}
            primaryAction={primaryAction}
            secondaryActions={secondaryActions}
        >
            <Modal.Section >
                <div className='export-customers-gap-0'>
                    {popUpContent}
                </div>
            </Modal.Section>
            {popUpContent1 && <div className='preview_section'>
                <Modal.Section>
                    <VerticalStack>
                        {popUpContent1}
                    </VerticalStack>
                </Modal.Section>
            </div>}
        </Modal>
    );
}

export default ActionModal;
import { Box, Divider, LegacyStack, Text } from '@shopify/polaris';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateMobileLaunchData } from '../../../redux/slice/DataSlice';
import CheckList from '../../../common-components/ChoiceList';
import OptionData from '../../../common-components/OptionData';
import Input from '../../../common-components/Input';
import InputField from '../../../common-components/InputField';

const Mobile = ({ formik }) => {
    const launcher_mobile = formik.values?.launcher_mobile;
    const [mobileLaunchData, setMobileLaunchData] = useState(launcher_mobile);
    const dispatch = useDispatch();

    useEffect(() => {
        setMobileLaunchData(formik.values);
    }, [formik.values?.launcher_mobile]);

    useEffect(() => {
        dispatch(updateMobileLaunchData(mobileLaunchData));
    }, [mobileLaunchData]);

    return (
        <>
            <Divider />
            <Box padding={300} >
                <LegacyStack vertical spacing='tight'>
                    <LegacyStack.Item>
                        <Text as="h3" variant="headingMd">
                            Select display-type
                        </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                        <CheckList
                            optionsData={[
                                { label: 'Text only', value: 'text' },
                                { label: 'Icon only', value: 'icon' }
                            ]}
                            name='launcher_mobile.visibility'
                            selected={[formik.values?.launcher_mobile?.visibility]}
                            handleChange={(name, value) => formik.setFieldValue(name, value[0])}
                        />
                    </LegacyStack.Item>
                </LegacyStack>
            </Box>
            <Divider />
            <Box padding={300}>
                <LegacyStack vertical spacing='tight'>
                    <LegacyStack.Item>
                        <Text as="h3" variant="headingMd">
                            Placement
                        </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                        <CheckList
                            optionsData={OptionData.placementOption}
                            name='launcher_mobile.mobile_placement'
                            selected={[formik.values?.launcher_mobile?.mobile_placement]}
                            handleChange={(name, value) => formik.setFieldValue(name, value[0])}
                        />
                    </LegacyStack.Item>
                    <Divider />
                    <LegacyStack.Item>
                        <Text as="h3" variant="headingMd">
                            Spacing
                        </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                        <Text color='subdued'>
                            This changes the position of the launcher-panel relative to the customer&#39;s screen
                        </Text>
                    </LegacyStack.Item>
                    <LegacyStack.Item>
                        <div className='form--group editor-flex-equal'>
                            <InputField
                                label='Side spacing'
                                type='number'
                                value={formik.values?.launcher_mobile?.mobile_side_spacing > screen.width ? screen.width : formik.values?.launcher_mobile?.mobile_side_spacing}
                                onChange={formik.setFieldValue}
                                suffix='Px'
                                name='launcher_mobile.mobile_side_spacing'
                                onBlur={formik.setFieldTouched}
                                error={formik.errors?.launcher_mobile?.mobile_side_spacing}
                                touched={formik.touched?.launcher_mobile?.mobile_side_spacing}
                                min={10}
                                max={40}
                            />

                            <InputField
                                label='Bottom spacing'
                                type='number'
                                value={formik.values?.launcher_mobile?.mobile_bottom_spacing > screen.height ? screen.height : formik.values?.launcher_mobile?.mobile_bottom_spacing}
                                onChange={formik.setFieldValue}
                                suffix='Px'
                                name='launcher_mobile.mobile_bottom_spacing'
                                onBlur={formik.setFieldTouched}
                                error={formik.errors?.launcher_mobile?.mobile_bottom_spacing}
                                touched={formik.touched?.launcher_mobile?.mobile_bottom_spacing}
                                min={10}
                                max={40}
                            />
                        </div>
                    </LegacyStack.Item>
                </LegacyStack>
            </Box>
        </>
    );
};

export default Mobile;
import React, { useMemo } from 'react';
// import { NavigationMenu, Provider, RoutePropagator } from '@shopify/app-bridge-react';
import { NavMenu, TitleBar } from '@shopify/app-bridge-react';
import { Outlet, Link } from 'react-router-dom';
import CommonRouter from '../helper/commonRoute';
const Dashboard = React.lazy(() => import('../pages/dashboard/Dashboard'));
const PointEditPage = React.lazy(() => import('../pages/program/PointEditPage'));
const RedeemPointEditPage = React.lazy(() => import('../pages/program/RedeemPointEditPage'));
const Nudges = React.lazy(() => import('../pages/nudge/Nudges'));
const NudgeEdit = React.lazy(() => import('../pages/nudge/NudgeEdit'));
const ReferralEdit = React.lazy(() => import('../pages/program/ReferralEdit'));
const Activity = React.lazy(() => import('../pages/activity/Activity'));
const ActivityDetail = React.lazy(() => import('../pages/activity/ActivityDetail'));
const CustomerList = React.lazy(() => import('../pages/customer/CustomerList'));
const CustomerDetails = React.lazy(() => import('../pages/customer/CustomerDetails'));
import { useDispatch, useSelector } from 'react-redux';
import { generateToken } from '../redux/slice/DataSlice';
import Branding from '../pages/branding/Branding';
import CustomerBulkEdit from '../pages/customer/CustomerBulkEdit';
import Settings from '../pages/settings/Settings';

// const Settings = React.lazy(() => import('../pages/settings/Settings'));

export function DefaultLayout() {
    const { titleBar } = useSelector(state => state.appBridge);
    const { title, breadcrumbs, primaryAction, secondaryActions } = titleBar || {};

    const dispatch = useDispatch();
    useMemo(async () => {
        await dispatch(generateToken()).unwrap();
    }, []);

    const status = useSelector(state => state?.data?.status);
    const shop_info = useSelector((state) => state.data.store_data?.shop_data);
    const onboarding_status = shop_info?.onboarding_steps;
    const scope_status = shop_info?.scope_status;
    return (
        <div>
            {status && (process.env?.MODE !== 'local' || window.location.protocol === 'https:') && onboarding_status === 4 && scope_status === '1' ? (
                <>
                    <NavMenu>
                        <Link to={CommonRouter.program}>Program</Link>
                        <Link to={CommonRouter.customers}>Customers</Link>
                        <Link to={CommonRouter.branding}>Branding</Link>
                        {/* <Link to={CommonRouter.nudges}>Nudges</Link> */}
                        {/* <Link to={CommonRouter.activity}>Activity</Link> */}
                        <Link to={CommonRouter.settings}>Settings</Link>
                
                        {/* <a href={`/pricingplan${self.location.search}`}>Pricing</a> */}
                    </NavMenu>
                    {/* <AppBridgeSaveBar /> */}
                    {Object.keys(titleBar)?.length > 0 && (
                        <TitleBar title={title}>
                            {breadcrumbs && (
                                <button onClick={breadcrumbs?.onAction} variant='breadcrumb'>
                                    {breadcrumbs.content}
                                </button>
                            )}
                            {primaryAction && (
                                <button variant='primary' onClick={primaryAction.onAction} disabled={primaryAction?.disabled} loading={primaryAction?.loading}>
                                    {primaryAction.content}
                                </button>
                            )}
                            {secondaryActions && secondaryActions.map((secondary, key) => (
                                <button onClick={secondary.onAction} key={key} disabled={secondary?.disabled} loading={secondary?.loading}>
                                    {secondary.content}
                                </button>
                            ))}
                        </TitleBar>
                    )}
                    <Outlet />
                </>
            ) : (
                status && <><Outlet /></>
            )}
        </div>
    );
}
export const routes = [
    { index: true, path: CommonRouter.program, element: <Dashboard /> },
    { index: true, path: `${CommonRouter.point}/:id`, element: <PointEditPage /> },
    { index: true, path: `${CommonRouter.redeem}/:id`, element: <RedeemPointEditPage /> },
    { index: true, path: CommonRouter.referral, element: <ReferralEdit /> },
    { index: true, path: CommonRouter.nudges, element: <Nudges /> },
    { index: true, path: `${CommonRouter.nudges}/:id`, element: <NudgeEdit /> },
    { index: true, path: CommonRouter.activity, element: <Activity /> },
    { index: true, path: `${CommonRouter.activity}/:id`, element: <ActivityDetail /> },
    { index: true, path: CommonRouter.customers, element: <CustomerList /> },
    { index: true, path: `${CommonRouter.customers}/:id`, element: <CustomerDetails /> },
    { index: true, path: `${CommonRouter.customers}/bulk`, element: <CustomerBulkEdit /> },
    { index: true, path: CommonRouter.branding, element: <Branding /> },
    { index: true, path: `${CommonRouter.settings}`, element: <Settings /> }
];

const OptionData = {
    min_require_options: [
        {label: 'None', value: 0},
        {label: 'Minimum purchase amount', value: 1}
    ],
    discount_options: [
        {label: 'Order discounts', value: 'order_discounts', helpText: 'Applies to both fixed amount and percentage off discounts'},
        {label: 'Shipping discounts', value: 'shipping_discounts'},
        {label: 'Product discounts', value: 'product_discounts'}
    ],
    shiping_discount_options: [
        {label: 'Order discount', value: '0', helpText: 'Applies to both fixed amount and percentage off discounts'},
        {label: 'Product discount', value: '2'}
    ],
    expiration_options: [
        {label: 'Months', value: 'months'},
        {label: 'Years', value: 'years'}
    ],
    order_collection: [
        {label: 'Entire order', value: 0},
        {label: 'Specific collection', value: 1}
    ],
    earning_type: [
        {label: 'Fixed amount of points', value: 'FIXED'},
        {label: 'Increments of points', value: 'INCREMENT'}
    ],
    limitPeriod: [
        {label: '1 day', value: '1'},
        {label: '30 days', value: '30'},
        {label: '60 days', value: '60'},
        {label: 'lifetime', value: '0'}
    ],
    earning_type1: [
        {label: 'Increments of points (recommended)', value: 'INCREMENT'},
        {label: 'Fixed amount of points', value: 'FIXED'}
    ],
    themeColorOptions: [
        { label: 'Primary', value: 'Primary' },
        { label: 'Secondary', value: 'Secondary' }
    ],
    themeFontOptions: [
        { label: 'Black', value: 'Black' },
        { label: 'White', value: 'White' }
    ],
    placementOption: [
        { label: 'Right', value: 'Right' },
        { label: 'Left', value: 'Left' }
    ],
    launcherOption: [
        { label: 'Icon With Text', value: 'icon_with_text' },
        { label: 'Text only', value: 'text' },
        { label: 'Icon only', value: 'icon' }
    ]
};

export default OptionData;

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import { AppProvider, Frame } from '@shopify/polaris';
import enTranslations from '@shopify/polaris/locales/en.json';

// import Store from './redux';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AppProvider i18n={enTranslations}>
        <Frame>
            <App />
        </Frame>
    </AppProvider>
);

/* If you want to start measuring performance in your app, pass a function
to log results (for example: reportWebVitals(console.log))
or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(); */

import axios from 'axios';
import Store from '../redux/store';

const { config_variable } = require('./config');

const service = axios.create({
    headers: {}
});
const handleSuccess = (response) => {
    return response;
};

const handleError = (error) => {
    return Promise.reject(error);
};
service.interceptors.response.use(handleSuccess, handleError);

export const ApiCall = async (method, path, payload, header = { 'Content-Type': 'application/json' }) => {
    try {
        const { status, store_data } = Store.getState().data;
        if (status) {
            header['authentication'] = store_data.token;
        }
        const responce = await service.request({
            method,
            url: config_variable.apiEndpoint + path,
            responseType: 'json',
            data: payload,
            headers: header
        });
        return responce;
    } catch (error) {
        console.log('axiosApiCall-----==', error);
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`);
        }
        if (error.response?.data?.statusCode === 401 && header && !header['access-token']) {
            if (error.response.data.refresh_expire) {
                return error.response;
            }
        } else {
            return error.response;
        }
    }
};

export const GetApiCall = async (method, path, header = { 'Content-Type': 'application/json' }) => {
    try {
        const { status, store_data } = Store.getState().data;
        if (status) {
            header['authentication'] = store_data.token;
        }
        const responce = await service.request({
            method,
            url:  config_variable.apiEndpoint  + path,
            responseType: 'json',
            headers: header
        });
        return responce;
    } catch (error) {
        console.log('axios---getapicall--==', error);
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`);
        }
        if (error.response?.data?.statusCode === 401) {
            return error.response;

        } else {
            return error.response;
        }
    }
};

import { configureStore } from '@reduxjs/toolkit';
import DataSlice from './slice/DataSlice';
import appBridgeSlice from './slice/appBridgeSlice';

const Store = configureStore({
    reducer:{
        data:DataSlice,
        appBridge: appBridgeSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: ['appBridge/setTitleBar', 'appBridge/setSaveBar', 'storeDetail/setCartMarkup', 'shopTracking/setShopCardMarkup'],
            ignoredPaths: ['appBridge.titleBar', 'appBridge.save_bar', 'storeDetail.products.cardMarkup', 'shopTracking.shopCardMarkup']
        }
    })
});

export default Store;
const CommonRouter = {
    program: '/',
    point: '/point',
    redeem: '/redeem',
    referral: '/referral',
    customers: '/customers',
    branding: '/branding',
    embeddedContent: '/embedded_content',
    customerEmails: '/customer_emails',
    nudges: '/nudges',
    integrations: '/integrations',
    billing: '/billing',
    settings: '/settings',
    activity: '/activity'
};
export default CommonRouter;

const CommonApiEndPoint = {
    generate_token: '/generate_token',
    onboarding: '/onboarding',
    earning_options: '/earnings',
    earningBulkEdit: '/ways-to-earn-points/bulk-status-change',
    updatePointsStatus: '/points-status-change',
    savePointsData: '/points-branding/save',
    getPointsData: '/points-data/get',
    points: '/points',
    redeemBulkEdit: '/ways-to-redeem-points/bulk-status-change',
    getReferralOptions: '/referrals/types',
    getShopifyCollections: '/shopify_collections ',
    getShopifyProducts: '/products',
    getReferralDetails: '/referral',
    getReferralList: '/referrals',
    getReferralData: '/referrals/data/get',
    saveReferralData: '/referrals/data/save',
    saveReferralStatus: '/referrals/status/save',
    redeem: '/redeem',
    redeemsList: '/redeems',
    nudgeType: '/nudges-types',
    nudgeList: '/nudges-lists',
    nudgeDetails: '/nudge/',
    customers: '/customers',
    customerActivity: '/activity',
    customerBulkFetch: '/customers-bulk-edit/fetch-details',
    customerBulkUpdate: '/customers-bulk-edit/update-details',
    customerRedeemPoints: '/customer/redeem-points',
    customerAmendPoints: '/customer/amend-points-balance',
    customerImportPoints: '/multiple-customers-adjust-points',
    customerRefundActivityPoints: '/activity/points/refund-points',
    getSettingsData: '/settings',
    saveSettingsData: '/settings/update',
    updateAppStatus: '/app-block-status-update',
    getAppBlockStatus: '/app-block-status-get',
    validateStatus: '/app-block-validate',
    getBranding: '/branding',
    updateBranding: '/branding-update',
    hideLauncherUrls: '/hide-launcher-urls-app-meta-fields/update',
    getStoreCurrencies :'/store-currencies',
    storeCreditAddCurrency: '/store-credit-add-currency',
    getStoreCreditDetail: '/store-credit-currencies',
    getDisabledStoreCurrency: '/disable-currencies',
    storeCreditCurrencyUpdate: '/store-credit-currency-update',
    scCurrencyStatusUpdate: '/store-credit-currency-status-change',
    storeCreditScopeUpdate: '/store-credit/url/get'
};
export default CommonApiEndPoint;

import React from 'react';

const InputField = ({ label, value, onChange, onBlur, suffix, name, max, min, maxLength, minLength, disabled, error, touched, errorInLabel=false, ...props }) => {

    function isIntNumberKey(evt) {
        if (props.type === 'number') {
            const charCode = (evt.which) ? evt.which : event.keyCode;
            if ((charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105) || [8, 37, 38, 40, 39, 190, 110, 46].includes(charCode)) {
                return true;
            }
            return false;
        }
        return true;
    }

    const handelOnKeyDown = (e, extra = ['.']) => {
        if (['-', '+', 'e', 'E', ...extra].includes(e.key) || e.keyCode === 69) {
            e.preventDefault();
        }
    };

    return (
        <div className={`input-field-main ${disabled ? 'disabled-input' : ''}`}>
            {label ? <div className="Polaris-Labelled__LabelWrapper">
                <div className="Polaris-Label">
                    <label id={name} htmlFor={name} className="Polaris-Label__Text">
                        <span className="Polaris-Text--root Polaris-Text--bodyMd">{label}</span>
                    </label>
                </div>
            </div> : null}
            <div className="Polaris-Connected">
                <div className="Polaris-Connected__Item Polaris-Connected__Item--primary">
                    <div className={`Polaris-TextField Polaris-TextField--hasValue ${disabled ? 'Polaris-TextField--disabled' : ''} ${error && touched ? 'Polaris-TextField--error' : ''} ${props.borderless ? 'Polaris-TextField--borderless' : ''}`}>
                        <input
                            name={name}
                            id={name} autoComplete="off" className="Polaris-TextField__Input" type="number" aria-labelledby=":Rq6:Label" aria-invalid="false" data-1p-ignore="true" data-lpignore="true" data-form-type="other"
                            value={value}
                            onChange={(e) => {
                                onChange(e.target.name, typeof Number(e.target.value) == 'number' ? e.target.value : '');
                            }}
                            disabled={disabled}
                            autoFocus={props.focused || false}
                            max={max}
                            min={min}
                            onKeyDown={(e) => {
                                if (isIntNumberKey(e)) {
                                    handelOnKeyDown(e);
                                } else {
                                    e.preventDefault();
                                }
                            }}
                            maxLength={maxLength}
                            minLength={minLength}
                            onBlur={() => (onBlur ? onBlur(name) : {})}
                            {...props}
                        />
                        {suffix ? <div className="Polaris-TextField__Suffix" id={`${name}-Suffix`}>{suffix}</div> : null}
                        <div className={`Polaris-TextField__Spinner ${disabled ? 'Polaris-TextField--disabled' : ''}`} aria-hidden="true">
                            <div role="button" className="Polaris-TextField__Segment" tabIndex="-1" onClick={() => {
                                if (!disabled) {
                                    onChange(name, (Number(value) || 0) + 1);
                                }
                            }}>
                                <div className="Polaris-TextField__SpinnerIcon">
                                    <span className="Polaris-Icon">
                                        <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                            <path fillRule="evenodd" d="M14.53 12.28a.75.75 0 0 1-1.06 0l-3.47-3.47-3.47 3.47a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06Z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                            <div role="button" className="Polaris-TextField__Segment" tabIndex="-1" onClick={() => {
                                if (!disabled) {
                                    if (min !== value) {
                                        onChange(name, (Number(value) || 0) - 1);
                                    }
                                }
                            }}>
                                <div className="Polaris-TextField__SpinnerIcon">
                                    <span className="Polaris-Icon">
                                        <svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true">
                                            <path fillRule="evenodd" d="M5.72 8.47a.75.75 0 0 1 1.06 0l3.47 3.47 3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z">
                                            </path>
                                        </svg>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="Polaris-TextField__Backdrop">
                        </div>
                    </div>
                </div>

                {props.connectedRight && <div className="Polaris-Connected__Item">
                    <div className="">
                        {props.connectedRight}
                    </div>
                </div>}
            </div>
            {!errorInLabel && error && touched && <div className="Polaris-Labelled__Error"><div id="[0].sign_up_discountError" className="Polaris-InlineError"><div className="Polaris-InlineError__Icon"><span className="Polaris-Icon"><span className="Polaris-Text--root Polaris-Text--visuallyHidden"></span><svg viewBox="0 0 20 20" className="Polaris-Icon__Svg" focusable="false" aria-hidden="true"><path d="M10 6a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5a.75.75 0 0 1 .75-.75Z"></path><path d="M11 13a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"></path><path fillRule="evenodd" d="M11.237 3.177a1.75 1.75 0 0 0-2.474 0l-5.586 5.585a1.75 1.75 0 0 0 0 2.475l5.586 5.586a1.75 1.75 0 0 0 2.474 0l5.586-5.586a1.75 1.75 0 0 0 0-2.475l-5.586-5.585Zm-1.414 1.06a.25.25 0 0 1 .354 0l5.586 5.586a.25.25 0 0 1 0 .354l-5.586 5.585a.25.25 0 0 1-.354 0l-5.586-5.585a.25.25 0 0 1 0-.354l5.586-5.586Z"></path></svg></span></div>{error}</div></div>}
            {props.helper ? <div className="Polaris-Labelled__HelpText" id=":rp:HelpText"><span className="Polaris-Text--root Polaris-Text--break Polaris-Text--subdued">{props.helper}</span></div> : null}
        </div>
    );
};

export default InputField;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SaveBar } from '@shopify/app-bridge-react';
import { PageActions } from '@shopify/polaris';
import { setSaveBar } from '../redux/slice/appBridgeSlice';
import { config_variable } from '../helper/config';
import { useNavigate } from 'react-router-dom';

const AppBridgeSaveBar = ({ onClick, loading, discardClick, saveAction, discardAction, leaveConfirmationDisable, visible, fullWidth, isShowShopify, savbtn, savemsg, disabled, url }) => {
    const [isSaveBarLoaded, setIsSaveBarLoaded] = useState(false);
    const [saveBarShowStatus, setSaveBarShowStatus] = useState(false);

    useEffect(() => {
        if (isShowShopify && !isSaveBarLoaded) {
            setIsSaveBarLoaded(true);
        }
    }, [isShowShopify, isSaveBarLoaded]);

    useEffect(() => {
        setSaveBarShowStatus(visible);
    }, [visible]);

    const handleSave = () => {
        saveAction?.onAction();
    };

    const handleDiscard = () => {
        window.shopify.saveBar.hide('globe-rm');
        discardAction?.onAction();
    };

    return (
        isShowShopify && saveBarShowStatus  ?  <SaveBar
            open={visible}
            id={'globe-rm'}
        >
            <button
                variant='primary'
                onClick={handleSave}
                loading={loading}
                disabled={saveAction?.disabled}
            />
            <button
                loading={discardAction?.loading}
                onClick={handleDiscard}
            />
        </SaveBar>  : visible && process.env?.MODE === 'local' && <PageActions
            primaryAction={{
                content: savbtn,
                loading,
                onAction: onClick,
                disabled
            }}
            secondaryActions={[
                {
                    content: 'Discard',
                    onAction: discardClick
                }
            ]}
        />
    );
};

export default React.memo(AppBridgeSaveBar);
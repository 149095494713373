import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    titleBar: {},
    prodNav: false,
    prodNavHome: false,
    active: false,
    tableData: [],
    save_bar: {
        id: 'globe-rm',
        show: false,
        onClick() { },
        loading: false,
        discardClick() { }
    }
};

export const appBridge = createSlice({
    name: 'appBridge',
    initialState,
    reducers: {
        setTitleBar: (state, action) => {
            state.titleBar = action.payload;
        },
        setProdNav: (state, { payload }) => {
            state.prodNav = payload;
        },
        setProdNavHome: (state, { payload }) => {
            state.prodNavHome = payload;
        },
        setActive: (state, action) => {
            state.active = action.payload;
        },
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setSaveBar: (state, { payload }) => {
            state.save_bar = { ...state.save_bar, ...payload };
        }
    }
});

export const { setTitleBar, setProdNav, setProdNavHome, setActive, setTableData, setSaveBar } = appBridge.actions;
export default appBridge.reducer;
import { FooterHelp, Link } from '@shopify/polaris';
import CryptoJS from 'crypto-js';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function Footer({ link }) {
    const store_name = useSelector(state => state.data.store_data.shop_data.store_name);
    const email = useSelector((state) => state.data.store_data.shop_data.email);

    useEffect(() => {
        window.Tawk_API = window.Tawk_API || {};
        (function () {
            const s1 = document.createElement('script'), s0 = document.getElementsByTagName('script')[0];
            s1.async = true;
            s1.src = 'https://embed.tawk.to/591d836c76be7313d291d64c/default';
            s1.charset = 'UTF-8';
            s1.setAttribute('crossorigin', '*');
            s0.parentNode.insertBefore(s1, s0);
        })();
        const hash = CryptoJS.HmacSHA256(`${email}`, 'ec91d8bd1a29b0cf2dbce2d372c5c6437536c98b');
        window.Tawk_API.visitor = {
            name: `${store_name}`,
            email: `${email}`,
            hash: `${hash}`
        };
    }, []);
    const chatOpen = () => {
        window.Tawk_API.maximize();
    };

    return (
        <div className='footercontent'>
            <FooterHelp>
                iWeb: Loyalty Rewards © 2024 - For more help visit our
                <Link url={link ? 'https://www.identixweb.com/helpdesk/' : ''} target='_blank'> FAQ</Link> or simply
                <Link onClick={chatOpen} url='javascript:void(0)'> Contact us</Link>
            </FooterHelp>
        </div>
    );
}

export default Footer;
import React from 'react';
import { Checkbox } from '@shopify/polaris';

function SingleCheckbox({ label, checked, handleChange, name, disabled, fill, helpText = false }) {

    const handleCheckbox = (name, e) => {
        if (handleChange) { handleChange(name, e); }
    };
    return (
        <Checkbox
            label={label}
            checked={checked}
            name={name}
            disabled={disabled}
            onChange={(e) => handleCheckbox(name, e)}
            fill={fill}
            helpText={helpText}
        />
    );
}


export default SingleCheckbox;

import React from 'react';
import { ChoiceList } from '@shopify/polaris';

function CheckList({ title, name, handleChange, selected, optionsData, allowMultiple = false, error }) {
    const handleOnChange = (selected, name) => {
        handleChange(name, selected);
    };
    return (
        <ChoiceList
            title={title}
            choices={optionsData}
            selected={selected}
            name={name}
            onChange={handleOnChange}
            allowMultiple={allowMultiple}
            error={error}
        />
    );
}

export default CheckList;

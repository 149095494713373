import { Box, HorizontalStack, Layout, LegacyCard, SkeletonBodyText, SkeletonDisplayText, VerticalStack } from '@shopify/polaris';
import React from 'react';

function SkeletonProgram() {
    return (
        <Layout sectioned>
            <Box padding={200}></Box>
            <LegacyCard sectioned>
                <VerticalStack gap={200}>
                    <SkeletonDisplayText size='small' />
                    <SkeletonBodyText lines={1.5}/>
                </VerticalStack>
            </LegacyCard>
            <LegacyCard sectioned>
                <VerticalStack gap={200}>
                    <SkeletonDisplayText size='small' />
                    <SkeletonBodyText />
                </VerticalStack>
            </LegacyCard>
            <LegacyCard sectioned>
                <VerticalStack gap={200}>
                    <SkeletonDisplayText size="small" />
                    <SkeletonBodyText />
                </VerticalStack>
            </LegacyCard>
        </Layout>
    );
}

export default SkeletonProgram;
import { Box, Button, Divider, FormLayout, HorizontalStack, LegacyStack, Text, TextField, Toast, VerticalStack } from '@shopify/polaris';
import React, { useCallback, useEffect, useState } from 'react';
import { DeleteMinor, PlusMinor } from '@shopify/polaris-icons';
import { useDispatch } from 'react-redux';
import { updateVisibilityData } from '../../../redux/slice/DataSlice';
import CheckList from '../../../common-components/ChoiceList';
import { ApiCall } from '../../../helper/axios';
import CommonApiEndPoint from '../../../helper/commonApiEndPoint';
import { config_variable } from '../../../helper/config';

const Visibility = ({ formik }) => {
    const theme_visibility = formik.values?.theme_visibility;
    const [hideLauncherUrlTag, setHideLauncherUrlTag] = useState([]);
    const [tagValue, setTagValue] = useState('');
    const [themeVisibilityData, setVisibilityData] = useState(theme_visibility);
    const [loading, setLoading] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const [active, setActive] = useState(false);
    const [validateMsg, setValidatMSg] = useState('');
    const toggleActive = useCallback(() => setActive((active) => !active), []);
    const dispatch = useDispatch();

    useEffect(() => {
        setVisibilityData(formik.values?.theme_visibility);
    }, [formik.values?.theme_visibility]);

    useEffect(() => {
        dispatch(updateVisibilityData(themeVisibilityData));
    }, [themeVisibilityData]);

    useEffect(() => {
        if (formik.values?.hide_launcher_on_urls_metavalue?.length) {
            setHideLauncherUrlTag(formik.values?.hide_launcher_on_urls_metavalue);
        }
    }, [formik.values?.hide_launcher_on_urls_metavalue]);

    async function removeTag(tag) { 
        const payload = {
            action: 'delete',
            url: tag
        };
        const res = await ApiCall('POST', CommonApiEndPoint.hideLauncherUrls, payload);
        if (res?.data?.status === 'SUCCESS') {
            const resData = res?.data?.data;
            const metafields = resData?.hide_launcher_on_urls_metavalue;
            setHideLauncherUrlTag([...metafields]);
            setLoading(false);
            setToastMessage(res?.data?.message);
            toggleActive();
        } else {
            setLoading(false);
            setToastMessage(res?.data?.message);
            toggleActive();
        }
    }

    async function handleAddTags() {
        try {
            const payload = {
                action: 'add',
                url: tagValue
            };
            if (tagValue?.length && !validateMsg) {
                setLoading('add');
                const res = await ApiCall('POST', CommonApiEndPoint.hideLauncherUrls, payload);
                const resData = res?.data?.data;
                if (res?.data?.status === 'SUCCESS' && resData?.hide_launcher_on_urls_metavalue?.length) {
                    const metafields = resData?.hide_launcher_on_urls_metavalue;
                    setTagValue('');
                    setHideLauncherUrlTag([...metafields]);
                    setLoading(false);
                    setToastMessage('URL added successfully!');
                    toggleActive();
                } else {
                    setLoading(false);
                    setToastMessage(res?.data?.message);
                    toggleActive();
                }
            }
        } catch (error) {
            console.log('error------------------', error);
        }
    }

    const tagMarkup = hideLauncherUrlTag.map((option, index) => (
        <div key={index}>
            <VerticalStack gap={200}>
                {index >= 1 && <Divider/>}
                <HorizontalStack align='space-between' blockAlign='center'>
                    <Text>{option}</Text>
                    <Button plain icon={DeleteMinor} onClick={() => { setLoading(`delete_${index}`); removeTag(option); }} loading={loading === `delete_${index}`}/>
                </HorizontalStack>
            </VerticalStack>
        </div>
    ));

    function getDomain(url, subdomain) {
        subdomain = subdomain || false;
    
        url = url.replace(/(https?:\/\/)?(www.)?/i, '');
    
        if (!subdomain) {
            url = url.split('.');
    
            url = url.slice(url.length - 2).join('.');
        }
    
        if (url.indexOf('/') !== -1) {
            return url.split('/')[0];
        }
    
        return url;
    }

    function validateURL() {
        let error = '';
        const domain = getDomain(tagValue, true);
        const shop_name = config_variable.shop_name;
        if (hideLauncherUrlTag.includes(tagValue)) {
            error =  'URL already exists in metafield value.';
        } else if (domain && domain.indexOf(shop_name) === -1) {
            error = 'Enter valid url!';
        } else {
            error = '';
        }
        setValidatMSg(error);
    }

    return (
        <>
            <Divider />
            <Box padding={300} >
                <Text as="h3" variant="headingMd">
                    Where to display the launcher
                </Text>
                <Box paddingBlockStart={200} paddingBlockEnd={200} >
                    <Text as="h3" variant="headingMd" fontWeight='regular'>
                        Visibility
                    </Text>
                </Box>
                <LegacyStack vertical spacing='none'>
                    <CheckList 
                        name='theme_visibility.where_to_display_launcher'
                        selected={[formik.values?.theme_visibility?.where_to_display_launcher]}
                        optionsData={[
                            { label: 'Desktop and mobile', value: 'desktop_and_mobile'},
                            { label: 'Desktop', value: 'Desktop'}
                        ]}
                        handleChange={(name, value) => formik.setFieldValue(name, value[0])}
                    />
                </LegacyStack>
            </Box>
            <Divider />
            <Box padding={300} >
                <VerticalStack gap={100}>
                    <Text variant="headingMd" as="h2">
                        On-page visibility
                    </Text>
                    <Text as="span" color="subdued">Hide Launcher on specified URL paths</Text>
                </VerticalStack>
            </Box>
            <Box paddingInlineEnd={300} paddingInlineStart={300} paddingBlockEnd={300}>
                <FormLayout>
                    <div>
                        <TextField
                            value={tagValue}
                            label="Add URL"
                            type="text"
                            placeholder='e.g. /blog'
                            connectedRight={
                                <Button fullWidth onClick={() => { handleAddTags(); }} loading={loading === 'add'}>Add</Button>
                            }
                            name='hide_launcher_on_urls_metavalue'
                            onChange={(value) => { setTagValue(value); setValidatMSg(''); }}
                            onBlur={validateURL}
                            error={validateMsg}
                        />
                    </div>
                    <VerticalStack gap={200}>
                        {tagMarkup}
                    </VerticalStack>
                </FormLayout>
            </Box>
            {active ? <Toast content={toastMessage || 'Request failed!'} onDismiss={toggleActive} duration={2000} /> : null}
        </>
    );
};

export default Visibility;